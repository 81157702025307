.wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
}
.tasks {

}
.report {

}