.modal {
    background-color: #ddddddad;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.container {
    box-shadow: #b4c1cd9e 2px 3px 7px 1px;
    padding: 4px;
    border-radius: 6px;
    background-color: #fbfbfb;
}

.hidden {
    display: none;
}