.wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    min-width: 300px;
}

.timer {
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-size: xxx-large;
    margin: 0.5em 0;
}

.timerInProgress {
    color: green;
}

.timerFinished {
    color: #a10404;
}

.actions {
    display: flex;
    justify-content: center;
}

.actionsButton {
    margin: 0 4px 15px;
}

.stat {
    flex: 1;
    justify-content: center;
}

.stat thead td {
    border-radius: 4px;
    padding: 6px;
    background-color: #0a1c741f;
    font-weight: bold;
    font-size: large;
}

.stat tbody td {
    border-bottom: 1px solid #cfcfcf;
    padding: 2px 10px;
    font-size: large;
}

.currentTimer {
    background-color: #c3ddb9;
}

@media (max-width: 520px) {
    .interval {
        visibility: hidden;
    }
}

.interval {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    right: 0;
    margin-top: 10px;
    margin-right: 20px;
}

.interval span {
    margin-bottom: 4px;
}

.invite {
    display: flex;
}

.inviteString {
    color: green;
}
.imageBig {
    width: 4em;
}