.buttonContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 60%;
    animation: showCategory 1s forwards;
}

@keyframes showCategory {
    from {
        opacity: 0;
        transform: translate(-100%);
    }
    to {
        opacity: 1;
        transform: translate(0);
    }
}

.buttonItem {
    margin: 10px 20px 20px;
    position: relative;
}

.buttonItem:hover .description {
    display: block;
}

.description {
    display: none;
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 5px;
    text-align: center;
    border-radius: 5px;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 320px;
    width: 320px;
    border: none;
    position: relative
}

.button:active {
    transform: scale(0.95);
}

.foodName {
    display: flex;
    justify-content: center;
    font-size: 19px;
    font-family: "Courier New", monospace;
}

@media (max-width: 1280px) and (max-height: 720px) {
    .button {
        height: 290px;
        width: 290px;
    }
    .imageUrl {
        height: 275px;
        width: 275px;
    }
    .buttonContainer {
        width: 100%;
    }
}

@media (max-width: 720px) and (max-height: 1280px) {
    .buttonContainer {
        width: 100%;
    }
}