.wrapper {
    /*border: 1px solid grey;*/
}
.addWrapper {
    display: flex;
}
.labelLine {
    display: flex;
    padding: 2px;
    border-bottom: 1px solid #f5f5f5;
}
.modal {
    padding: 10px;
}
.modalButtons {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
}