.wrapper {

}

.window {
    margin-top: 50px;
    padding: 10px 15px;
    border: 1px solid #d1d1d1;
    border-radius: 6px;
    background-color: #fdfdfd;
}
.title {
    margin-bottom: 10px;
}
.box {
    margin-bottom: 10px;
}
.panel {
    display: flex;
    justify-content: flex-end;
}
.links {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.link {
    color: #0054ad;
    text-decoration: underline;
    cursor: pointer;
    user-select: none;
}