.wrapper {
    min-width: 400px;
}

.buttonWrapper{
    display: flex;
    justify-content: flex-end;
}

.button {
    margin: 4px;
}

.topText {
    display: flex;
    justify-content: flex-start;
    padding: 10px;
    border-bottom: 1px solid grey;
    color: #504848;
}

.middleText {
    display: flex;
    padding: 10px;
    justify-content: flex-start;
}

.middleTextPart {
    font-weight: bolder;
    color: #504848;
}