.defaultButton {
    color: var(--main-text-color);
    background-color: var(--bg-color);
}

.activeButton img {
    filter: url(#filter_2)
}

.activeButton {
    color: var(--bg-color);
    background-color: var(--main-text-color);
}

.button {
    flex: 1;
    cursor: pointer;
    display: flex;
    padding: 2px 4px;
    border: 1px solid #d3d3d3;
    border-radius: 4px;
    font-weight: bold;
    align-items: center;
    justify-content: center;
}

.defaultButton:hover {
    background-color: var(--main-text-color);
    color: var(--bg-color);
}

.defaultButton img{
    filter: url(#filter_1);
    transition: fill .3s;
}

.defaultButton:hover img {
    filter: url(#filter_2);
}