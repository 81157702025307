.wrapper {
    display: flex;
    flex-direction: column;
}
.activeTasks {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.line {
    display: flex;
    justify-content: space-between;
}
.taskList {
    display: flex;
    justify-content: center;
}