.wrapper {
    margin: 2px;
}

.colorPickerContainer {
    position: relative;
}

.colorPicker {
    position: absolute;
    top: 100%;
    left: 0;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 1px;
}

.topElement {
    user-select: none;
    border-radius: 4px 4px 0 0;
    background-color: var(--bg-color);
    width: 32px;
    height: 20px;
}

.topElement svg path {
    fill: var(--main-text-color);
}

.topElement:hover svg path {
    fill: var(--bg-color);;
}

.topElement:hover {
    background-color: var(--main-text-color);
}

.botElement {
    user-select: none;
    border-radius: 0 0 4px 4px;
    background-color: var(--bg-color);
    width: 32px;
    height: 10px;
}

.botElement:hover svg line {
    stroke: var(--bg-color);
}

.botElement:hover {
    background-color: var(--main-text-color);
}

.botElement svg {
    display: block;
}

.button {
    margin: 2px;
    width: 2em;
}

.clearButton {
    margin: 2px 7px 2px 2px;
    width: 2em;
}

.modal {
    margin: 5px;
}