.rotateLittleArrow {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.littleArrow {
    display: flex;
    align-items: center;
    transform: rotate(-90deg);
    cursor: pointer;
}