.wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 2px 0;
}
.pagePart {
    display: flex;
}
.actionElement {
    margin-left: 5px;
}
.modalWindow {
    padding: 10px;
    max-height: 400px;
    overflow-y: auto;
}

.headerName {
    display: flex;
    flex: 1;
    justify-content: center;
    justify-items: center;
}

.modalElement {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}

.editList {
    width: 2em;
}

.modalContent {
}
.listItemWrapper {
    display: flex;
    flex-direction: column;
}
.listItemRow {
    display: flex;
    min-height: 2em;
    border-radius: 6px;
}

.listItemPageName:hover {
    background: linear-gradient(0deg, #00000000 10%, rgba(112, 178, 102, 0.18) 50%, #00000000 90%);
}

.dotMenu {
    display: flex;
}

.listItemPageName {
    overflow: hidden;
    margin-left: 10px;
    flex: 1;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.listItemChildren {
    margin-left: 15px;
}
.radio {
    margin: 2px;
}

.infoPageContent {
    display: flex;
}

.addElement {
    display: flex;
    margin: 5px 0 0 1px;
}