.wrapper {
    display: flex;
    user-select: none;
    min-height: 2em;
    flex-direction: column;
}

.contentContainer {
    display: flex;
    flex: 1;
}
