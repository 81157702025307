.wrapper {
    display: flex;
    /*flex: 1;*/
}

.sideMenu {
    position: fixed;
    width: 250px;
    left: 0;
    bottom: 0;
    top: 83px;
    
    background-color: #e3e1e1;
    display: flex;

    max-width: 600px;
    min-width: 100px;
    flex-direction: row;
    user-select: none;
}

.sideMenuContent {
    top: 0;
    overflow-y: auto;
    width: 100%;
    bottom: 0;
}

.sideMenuElement {
    display: flex;
    justify-content: flex-end;
}