.wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.chartBox {
    display: flex;
    justify-content: center;
    height: 400px;
}

.DatePicker {
    display: flex;
    width: 80px;
}