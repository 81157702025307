.wrapper {
    display: flex;
}
.userFullInfo {
    flex: 1;
    margin: 2px;
    padding: 15px;
    border: 1px solid #d3d3d3;
    border-radius: 4px;
}
.userTextInfo {
    display: flex;
}
.inputData {
    display: flex;
    flex-direction: column;
}
.inputLogin {
    padding: 4px;
}
.inputEmail {
    padding: 4px;
}
.userDate {
    margin-left: 40px;
}
.roleGroup {
    display: flex;
    margin-top: 10px;
    align-items: center;
}

.buttonAddRole{
    margin-left: 10px;
    margin-top: 5px;
}
.modal {
    padding: 10px;
}
.role {
    border: 1px solid black;
}