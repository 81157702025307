.wrapper {
    display: flex;
}

.textArea {
    outline: none;
    overflow: auto;
    background-color: transparent;
    border-radius: 4px;
    flex: 1;
    resize: none;
    border: 1px solid #f99200;
}

.additionalInfoWindow {
    display: flex;
    flex-direction: column;
    background-color: #f1e4e4;
    padding: 5px;
    border-radius: 6px;
    position: absolute;
    width: 408px;
    height: 200px;
    margin-top: -248px;
    margin-left: -390px;
    z-index: 2;
}

.commentButton {
    width: 2em;
}