.wrapper {
    display: flex;
}
.input {
    flex: 1;
    padding: 0 5px;
    border: none;
    background-color: #00000000;
    border-bottom: 1px solid #B8D9A700;
    min-height: 2em;
    font-size: 1.1em;
    text-align: center;
}
.input:focus {
    color: #0a1c74;
    border-bottom: 1px solid #b9d9a8;
    outline: none;
}