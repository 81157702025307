.wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateX(500px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.topLine {
    animation: slideIn 1s forwards;
    display: flex;
    background-color: lightblue;
    justify-content: space-between;
    align-items: center;
    margin-top: 4px;
}

.topButton {
    padding: 4px;
}

.sideMenu {
    position: fixed;
    width: 250px;
    left: 0;
    bottom: 0;
    top: 84px;

    background-color: #d1d0d7;
    display: flex;

    max-width: 600px;
    min-width: 100px;
    flex-direction: row;
    user-select: none;
}

.sideMenuContent {
    top: 0;
    overflow-y: auto;
    width: 100%;
    bottom: 0;
}

.content {
    display: flex;
    flex: 1;
}

.receiptInfo {
    display: flex;
    justify-content: center;
    margin: 0 0 0 4px;
    flex: 1;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-thumb {
    background: rgba(80, 71, 71, 0.52);
    border-radius: 3px;
}