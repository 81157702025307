.wrapper {
    display: flex;
    flex-direction: column;
}

.groupContainer {
    flex: 1;
    cursor: pointer;
    margin: 2px;
    padding: 5px 4px;
    border: 1px solid #d3d3d3;
    border-radius: 4px;
    color: #3a6565;
    background-color: #f2ffff;
}

.groupReceiptItem {
    margin-left: 20px;
}

.groupContainer:hover {
    background-color: #3a6565;
    color: white;
}

.groupContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.element {
    display: flex;
}

.groupName {
    overflow: hidden;
}