.wrapper {
    display: flex;
    flex-direction: column;
    max-width: 900px;
    flex: 1;
    margin-top: 3px;
}

.Th {
    color: #4f4e4e;
}

.input {
    padding: 3px;
}

.buttons {
    margin: 3px;
    max-width: 2em;
}

.image {
    width: 190px;
    height: 190px;
}

.imageContainer {
    margin: 0 0 5px 10px ;
    flex: 1;
    display: flex;
    justify-content: flex-start;
}

.description {
    display: flex;
}

.created {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 10px 0;
}

.header {
    display: flex;
}

.receiptName {
    flex: 1;
    display: flex;
    justify-content: center;
}

.receipt {
    border-top: 2px solid #4f4e4e;
    display: flex;
    justify-content: center;
}

.inputArea {
    width: 90%;
    height: 170px;
}

.addProduct {
    margin: 10px 0 10px 0;
    display: flex;
    justify-content: center;
}

.viewProducts {
    display: flex;
    justify-content: center;
}

.resultLine {
    margin: 10px 8px 0 0;
}