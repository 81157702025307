.wrapper {
    flex: 1;
    display: flex;
    margin-top: 30px;
    margin-bottom: 20px;
    justify-content: flex-end;
}

.line {
    display: flex;
    justify-content: space-between;
    text-shadow: 0 0 8px #ffbc00;
    font-weight: bold;
}

.dateText {
    cursor: pointer;
    user-select: none;
    display: flex;
    min-width: 2em;
    justify-content: center;
}

.dateElement {
    display: flex;
    justify-content: center;
    align-items: center;
}

.celWeekend {
    box-shadow: 0 0 3px #9a4aab;
}

.cellHeader {
    color: #655757;
    min-width: 2em;
    display: flex;
    justify-content: center;
    user-select: none;
    font-weight: bold;
    padding: 4px;
    border: 1px solid #2f2a2a38;
}

.cell {
    display: flex;
    height: 26px;
    width: 100%;
    cursor: pointer;
    user-select: none;
    border: 1px solid #2f2a2a38;
}
.calendarDates {
    min-width: 21em;
    display: flex;
    flex-direction: column;
}

.selectedDate {
    color: #655757;
    font-weight: bold;
    box-shadow: 0 0 4px #1e1789;
}

.arrow {
    margin-top: 2px;
    width: 15px;
}

.cellContent {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
}

.svgGroup {
    display: flex;
}