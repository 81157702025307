.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.element {
    margin: 5px;
}

.completedButton {
    margin: 5px;
}

.addButton {
    margin-left: 10px;
}

.profitButton {
    margin-left: 5px;
}