.wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.topLine {
    background-color: #eff0ff;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 4px;
}

.pageContent {
    display: flex;
    justify-content: center;
    flex: 1;
}

.buttonGroup {
    display: flex;
}