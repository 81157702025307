.wrapper {
    display: flex;
    justify-content: center;
}

.transaction {
    border: 1px solid black;
    display: flex;
}

.transactionList {
    display: flex;
    margin: 5px;
}

.input {
    border: none;
    background-color: rgba(0, 0, 0, 0);
    border-bottom: 1px solid #23242536;
    text-align: center;
}

.cashTD {
    border: none;
    text-align: center;
    padding: 3px;
}