.wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.noContent {
    display: flex;
    justify-content: center;
    flex: 1;
}

.wrapper td{
    border: 1px solid black;
    padding: 0 8px;
}
.wrapper code {
    box-shadow: 0 0 15px #00000040;
    padding: 5px;
    background-color: #ececec;
    border: 1px solid #b7b7b7;
    border-radius: 4px;
    display: inline-flex;
}
.actions {
    display: flex;
    margin: 5px;
}
.needToSave {
    outline: 2px solid #f33030;
}

.notNeedToSave {
    outline: 2px solid #76f66d;
}

.pageText {
    margin-left: 5px;
    overflow: auto;
}

.wrapper ul {
    margin: 0;
}

.wrapper ol {
    margin: 0;
}