.wrapper {
    display: flex;
    width: 100%;
    height: 100%;
}

.area {
    flex: 1;
    resize: none;
    width: 100%;
    height: 100%;
}

.invisibleTextArea {
    border: none;
}
