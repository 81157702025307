.wrapper {
    display: flex;
    flex-direction: column;
    min-height: 10em;
    min-width: 10em;
}

.header {
    display: flex;
    justify-content: center;
}

.buttons {
    display: flex;
    justify-content: space-between;
}

.button {
    flex: 1;
    max-width: 2em;
}

.cancel {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1;
}