.wrapper {
    min-width: 400px;
}

.buttonWrapper {
    display: flex;
    justify-content: flex-end;
}

.input {
    display: flex;
    padding: 10px;
    justify-content: flex-start;
}

.button {
    margin: 4px;
}

.topText {
    padding: 10px;
    border-bottom: 1px solid grey;
}