.ButtonsTextEditor {
    display: flex;
}
.button {
    margin: 2px;
    width: 2em;
}
.specialButtons {
    display: flex;
}
.wrapper {
    display: flex;
}