.wrapper {
    display: flex;
    flex-direction: column;
}

.inputGroup {
    display: flex;
    flex-direction: column;
}

.input {
    margin: 4px;
}

.inputArea {
    height: 80px;
    margin: 4px;
}

.buttonGroup {
    display: flex;
}

.button {
    flex: 1;
}