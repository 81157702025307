.wrapper {
    min-width: 400px;
}


.topText {
    padding: 10px;
    border-bottom: 1px solid grey;
}

.buttonWrapper {
    display: flex;
    justify-content: flex-end;
}

.input {
    display: flex;
    justify-content: flex-start;
    padding: 10px;
}

.button {
    margin: 4px;
}