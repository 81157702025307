.wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
}
.createNewButton {
    margin: 1em 0;
}
/*
*/
