body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}
:root {
  --main-text-color: #3a6565;
  --bg-color: #f2ffff;
}

#root {
  display: flex;
  position: absolute;
  flex-direction: column;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

h1, h2, h3, h4, h5 {
  margin: 0;
}