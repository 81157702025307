.wrapper {
    position: relative;
    display: flex;
}

.dropdownButton {

}

.dropdownMenu {
    position: absolute;
    min-width: 100px;
    background-color: #fff;
    border: none;
    border-radius: 3px;
    box-shadow: 0 0 15px rgba(56, 59, 66, 0.25);
    z-index: 9999;
    right: 30%;
    top: 80%;
}

.menuList {
    list-style-type: none;
    margin: 0;
    width: 100%;
    padding: 0;
}

.menuItem {
    display: flex;
    cursor: pointer;
    margin: 4px;
    color: #7c7878;
}

.menuItem:hover {
    background-color: #c7c0c0;
}