
.wrapper td{
    border: 1px solid black;
    padding: 0 8px;
}

.wrapper ul {
    margin: 0;
}

.wrapper ol {
    margin: 0;
}

.wrapper code {
    box-shadow: 0 0 15px #00000040;
    padding: 5px;
    background-color: #ececec;
    border: 1px solid #b7b7b7;
    border-radius: 4px;
    display: inline-flex;
}
.editor {
    width: 100%;
    min-height: 20em;
    border: none;
}

.textContainer {
    overflow: auto;
}

.wysiwygContent {
    margin: 5px;
}

.area {
    flex:1;
}

.editorButtons {
    display: flex;
    align-items: center;
}

.additionalButtons {
    margin-left: 10px;
    display: flex;
    align-items: center;
}

.buttonGroup {
    display: flex;
    justify-content: space-between;
    background-color: lightblue;
    margin-bottom: 4px;
    align-items: center;
    border-radius: 4px;
}

.contentWrapper {

}

.showCode {
    margin: 0 4px;
    width: 2em;
}
.needToSave {
    outline: 1px solid #f33030;
}
.notNeedToSave {
    outline: 1px solid #76f66d;
}
.button {
    margin: 0 1px;
    width: 2em;
}
.tableButtons {
    display: flex;
}
