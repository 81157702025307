.wrapper {
    border: 1px solid #7e7e7e45;
    height: 2em;
}
.line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 14em;
    text-shadow: 0 0 8px #ffbc00;
    font-weight: bold;
}
.dateText {
    cursor: pointer;
    user-select: none;
    display: flex;
    height: 100%;
    align-items: center;
}
.pickDialogue {
    margin-top: 5px;
    background-color: #fcfcff;
    border-radius: 6px;
    position: absolute;
    z-index: 100;
    padding: 10px;
    box-shadow: 1px 3px 8px #737373;
    animation: onShow 0.2s forwards;
}
.pickDialogue table {
    width: 100%;
}
.cell {
    display: flex;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    padding: 2px;
    border-radius: 10%;
    height: 1.5em;
}
.celWeekend {
    text-shadow: 0 0 10px #44f11d;
}
.cellHeader {
    display: flex;
    justify-content: center;
    user-select: none;
    font-weight: bold;
    margin: 2px;
}
.cell:hover {
    background-color: rgba(197, 35, 255, 0.14);
}
.svgMonthSegment:hover{
    fill: blue;
}
.svgText {
    user-select: none;
    pointer-events: none;
}
.year {
    margin-top: 20px;
}

@keyframes onShow {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}